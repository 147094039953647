.container-gallery {
  padding: 20px;
}

.heading-content {
  text-align: center;
  margin-bottom: 20px;
}

.campaign-name-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.campaign-name {
  color: #000;
  text-align: center;
  /* font-family: "Inter", Helvetica; */
  font-size: 46px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
}

.image-container1 {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.image-item {
  cursor: pointer;
}

.image-item img {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
}

.item-image {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
}

.item-image-skelton {
  min-height: 300px;
}

.tags {
  display: flex;
  height: 25.303px;
  padding: var(--spacing-xs, 4px) 10px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex-shrink: 0;
  border-radius: var(--radius-sm, 6px);
  border: 1px solid var(--Colors-Border-border-primary, #d0d5dd);
  background: var(--Colors-Background-bg-primary, #fff);
  color: var(--colors-text-text-secondary-700, #344054);
  text-align: center;
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-middle {
  display: block;
  margin: 0 auto;
  text-align: center;
}
