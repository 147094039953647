
.textarea {
    outline: none;
    resize: none;
    overflow-y: scroll; 
    scrollbar-width: thin; 
    scrollbar-color: transparent transparent; 
  }
  
  
  .textarea::-webkit-scrollbar {
    width: 6px; 
    height: 6px; 
  }
  
  .textarea::-webkit-scrollbar-thumb {
    background-color: transparent; 
  }
  
  .textarea::-webkit-scrollbar-track {
    background-color: transparent; 
  }
  
  
  .scroll-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    color: #888;
    transition: background-color 0.3s;
  }
  
  .scroll-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .scroll-button:focus {
    outline: none;
  }
  