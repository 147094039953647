.login-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh; /* Ensure full viewport height */
}

.loginform {
  display: flex; /* Enable flexbox for the body */
  min-height: 100vh; /* Ensure full viewport height */
  justify-content: center; /* Vertically center child elements */
  align-items: center; /* Optionally center horizontally */
}

.login-form-wrapper,
.login-image-wrapper {
  flex: 1; /* Divide space equally for desktop (optional) */
}

@media (max-width: 768px) {
  /* Mobile view: Hide image and stack form and footer */
  .login-image-wrapper {
    display: none;
  }

  .login-form-wrapper {
    flex: auto; /* Allow form to fill remaining space */
  }

  .login-container {
    flex-direction: column; /* Stack login form and footer */
  }
}

/* Image Styling for Responsiveness */
.login-image-wrapper {
  position: relative; /* Required for background image approach */
  height: 100vh;
}

.login-image {
  width: 100%; /* Optional for background image */
  object-fit: fill; /* Stretch image to fill container */
  height: 100%; /* Set image height explicitly */
}

.button-primary-sw {
  width: 180px;
  height: 41.92px;

  padding-top: 10.46px;
  padding-bottom: 10.46px;
  background: #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 1.05px #7f56d9 solid;

  gap: 4px;

  padding-left: 2px;
  padding-right: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-size: 14px;
  /* font-family: "Inter", Helvetica; */
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
}
