.footer {
    padding: 1rem;
    background-color: #ffffff;
  
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
  
    /* font-family: Inter; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    border-top: 1px solid #7F56D9;
  }
  
  .footer-content {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    gap: 25px;

    width: 100%;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  }