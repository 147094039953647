@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("/public/font/Inter/Inter-VariableFont_slnt\,wght.ttf");
}

p div {
  font-family: "Inter";
}

body {
  margin: 0;
  font-family: "Inter",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --success-green: #067647;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-border-violet {
  border: 1px solid #D7BBFA !important;
}

.custom-border-b-violet {
  border-bottom: 2px solid #6941C6 !important;
}

.custom-border-b-silver {
  border-bottom: 2px solid #D0D5DD !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}


.custom-max-width {
  max-width: 1920px !important;
}

.css-13cymwt-control,
.box-control {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.15) !important;
  /* #1018280D  */
  border: 1px solid #D0D5DD !important;
  border-radius: 8px !important;
}



.textarea:focus,
.input:focus,
.box-shadow-default:focus {
  box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24) !important;
  border: 1px solid #D6BBFB !important;
  outline: none !important;
}

.border-default {
  border: 1px solid #D6BBFB !important;
}


#content_form_tiff_container #tiff-inner-container {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

#content_form_tiff_container canvas {
  height: auto;
  width: 100%;
  max-width: 700px;
  max-height: 500px;
}

input::placeholder,
textarea::placeholder,
.placeholder-color {
  color: #667085;
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}