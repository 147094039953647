.publish-btn{
    background: var(--Component-colors-Utility-Success-utility-success-50, rgba(236, 253, 243, 1));
    border: 1px solid var(--Component-colors-Utility-Success-utility-success-200, rgba(171, 239, 198, 1));
    color: var(--Component-colors-Utility-Success-utility-success-700, rgba(6, 118, 71, 1));

}

.unpublish-btn{
    background: var(--Component-colors-Utility-Gray-utility-gray-50, rgba(249, 250, 251, 1));
    border: 1px solid var(--Component-colors-Utility-Gray-utility-gray-200, rgba(234, 236, 240, 1))
}