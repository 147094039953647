.breadcrumb {
 /*padding: 8px 0px;*/
 padding-top: 20px;
 /*margin: 20px 10px;
  /* font-family: "Inter", Helvetica; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  margin-top: 4px;
}
.breadcrumb-item{
  display: flex;
  align-items: center;
}
.breadcrumb-item a{
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: #000000;
  /* font-family: "Inter", Helvetica; */
  display: flex;
  align-items: center; /* This centers content vertically */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.breadcrumb-item.active {
  color: #000000;
  display: flex;
  align-items: center;
  font-weight: 700;
}
