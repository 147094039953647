@import url("../components/MainNavigationguide.css");

:root {
  /* ===== Colors ===== */
  --body-color: #fff;
  --sidebar-color: #667085;
  --primary-color: #7f56d91a;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #344054;

  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}
* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
  /* font-family: "Inter", Helvetica; */
  /* font-weight: 400; */
  line-height: 1.5;
  font-size: 14px;
}

.menu-bar .nav-text{
  font-weight: 600;
}

/* Overlay styles */
.overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*  background-color: rgba(0, 0, 0, 0.3); Add opacity with black color */
  opacity: 0; /* Initially hide the overlay */
  pointer-events: none; /* Allow interactions through the overlay */
  transition: opacity 0.3s ease-in-out;
  /* z-index: 1; /* Ensure overlay sits above other elements */
}

.overlay.active {
  opacity: 1; /* Show the overlay when sidebar is open */
  pointer-events: auto; /* Allow interactions with the overlay */
}

/* Content area styling with overlay */

/* Basic styles for sidebar and content */
.sidebar {
  width: 225px;
  background-color: #ffffff;

  position: fixed;
  bottom: 0;
  left: 0;
  height: 90vh;
  /* overflow-y: auto; Allow scrolling in sidebar if needed */
  transition: all 0.3s ease-in-out;
  padding: 10px 14px;

  border-color: rgba(234, 236, 240, 1);

  border-right-width: 1.05px;
  border-right-style: solid;
  z-index: 100;
}

.sidebar.close {
  /* transform: translateX(-90%); Hide sidebar when closed */
  width: 92px;
}

.sidebar header {
  position: relative;
  justify-content: center;
}

.sidebar li {
  height: 40px;
  list-style: none;
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
}

.sidebar header .image,
.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
}

.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.sidebar .text,
.sidebar .icon {
  color: var(--text-color);
  transition: var(--tran-03);
}

.sidebar.close .text {
  opacity: 0;
}
/* =========================== */

.sidebar header .image-text {
  display: flex;
  align-items: center;
}

.sidebar header .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar header .image img {
  width: 40px;
  border-radius: 6px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: var(--primary-color);
  color: var(--sidebar-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}

.sidebar .menu {
  margin-top: 40px;
}

.sidebar li a {
  list-style: none;
  height: 75%;
  /* background-color: transparent; */
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: var(--tran-03);
}

.active-tab {
  background-color: #7F56D91A !important;
  border-radius: 8px;
}

.sidebar li:hover {
  background-color: var(--primary-color);
  border-radius: 8px;
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--sidebar-color);
}

.sidebar .menu-bar {
  height: calc(100% - 96px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}
.menu-bar::-webkit-scrollbar {
  display: none;
}
/* .content {
  padding: 1rem;
} */
.menu-btn-customized {
  top: 2rem;
  left: 3rem;
  position: absolute;
  padding: 0.5rem 1rem;
  background-color: #7f56d9;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  z-index: 1;
}

body {
  min-height: 100vh;
  background-color: var(--body-color);
}

::selection {
  background-color: var(--primary-color);
  color: #fff;
}

/* ===== Reusable code - Here ===== */

.sidebar li.search-box {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar li.search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--primary-color-light);
  color: var(--text-color);
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
  transition: var(--tran-05);
}

.sidebar .menu-bar .mode {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  position: relative;
  transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
  height: 50px;
  width: 60px;
}

.mode .sun-moon i {
  position: absolute;
}
.mode .sun-moon i.sun {
  opacity: 0;
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}
.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--toggle-color);
  transition: var(--tran-05);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
  transition: var(--tran-04);
}

.home {
  position: absolute;
  top: 0;
  top: 0;
  left: 250px;
  height: 100vh;
  width: calc(100% - 250px);
  background-color: var(--body-color);
  transition: var(--tran-05);
}
.home .text {
  font-size: 30px;
  font-weight: 500;
  color: var(--text-color);
  padding: 12px 60px;
}

.name-holder {
  justify-content: center;
}

.sidebar.close ~ .home {
  left: 78px;
  height: 100vh;
  width: calc(100% - 78px);
}

.swiirl-logo-sidebar {
  height: 35px;
  width: 80px;
}


.menu-icon{
  display: flex;
  justify-content: start;
  cursor: pointer;
  /* margin-bottom: 20px; */
} 
.menu-icon-close{
  justify-content: end;
}