.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.checkbox-container input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
    background-color: white;
    border: 2px solid rgb(170, 163, 163);
    border-radius: 4px;
    margin-right: 10px;
    display: inline-block;
}

.custom-checkbox.checked {
    border: 0;
    background-color: #7F56D9;
}

.custom-checkbox.unchecked{
 border : 2px solid #242221;
 /* background-color: #FEE4E2; */
}

.checkbox-box input[type="checkbox"]:checked ~ .custom-checkbox::after {
    content: "";
    position: absolute;
    left: 7px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.label-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.coming-soon {
    color: gray;
    font-size: 0.85em;
    margin-left: 5px;
}


