.already-have-an-acco {
  align-self: stretch;
  color: var(--colors-text-text-secondary-700, #344054);
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.text-wrapper-4 {
  color: #4285f4;

  /* Text sm/Semibold */
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  /* font-family: "Inter", Helvetica; */
}

.text-wrapper-3 {
  color: var(--colors-text-text-tertiary-600, #475467);
  /* Text sm/Regular */
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  /* font-family: "Inter", Helvetica; */
}
