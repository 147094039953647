.radio-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    margin: 10px 0;
  }
  
  .radio-container input {
    display: none;
  }
  
  .custom-radio {
    position: relative;
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid rgb(170, 163, 163);
    margin-right: 10px;
    transition: background-color 0.2s ease;
  }
  
  .radio-container input:checked + .custom-radio {
    background-color: #7F56D9;
  }
  
  .custom-radio::after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .radio-container input:checked + .custom-radio::after {
    display: block;
  }
  
  .radio-container .custom-radio::after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
    transform: translate(-50%, -50%);
  }
  

  .lable-text{
    font-size: 1.1rem;
    color: #475467;
  }

.coming-soon {
    display: none; /* Hide by default */
    margin-left: 8px; /* Add some space between the label and the text */
    color: #888; /* Optional: style for visibility */
}

.radio-container:hover .coming-soon {
    display: inline; /* Show the text on hover */
}
