/* Card Container */
.card-container {
  /* display: flex; */

  align-items: center;
  gap: 30px;
  padding: 5px 20px;
  /* flex-wrap: wrap; Allows wrapping on smaller screens */
  /* display: grid; */
  /* grid-template-columns: auto auto; */
}

/* Card */
.card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  min-width: 450px;
  width: 100%;
  /* margin: 20px; */
  display: flex;
  flex-direction: column;
}

.first-card {
  min-width: 750px !important;
  width: 100%;
}

.card-images {
  display: flex;
  gap: 5px;
  height: 360px; 
}

.column-half {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* justify-content: space-between; */
  height: 366px;
}

.column-full {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 366px;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.card-image-half {
  width: 100%;
  /* height: 50%; */
  height:100%;
  object-fit: cover;
  cursor: pointer;
}

.card-content {
  padding: 20px;
  flex: 1; /* Takes up remaining space */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-title {
  /* font-family: Inter, sans-serif; */
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 10px;
}

.card-description {
  /* font-family: Inter, sans-serif; */
  font-size: 14px;
  color: #555;
  margin: 0 0 20px;
  flex: 1; /* Takes up remaining space */
}

.card-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gallery-label {
  color: #000;
  /* font-family: Inter, sans-serif; */
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 100% */
  width: 100%;
  margin: 10px 20px;
}

.avatar-image {
  height: 45px;
  width: 45px;
  margin: 5px;
}

/* Title and Description */
.title-description {
  flex: 1; /* Take up remaining space */
  justify-content: space-between;
  margin-left: 20px;
}

.card-link {
  text-decoration: none;
}

.height-50 {
  height: 50%;
}