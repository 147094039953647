.alertClass {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.gallery-section {
    display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
}

.girdImage {
    height: auto;
}

.imageSelected {
    padding: 27px;
    background-color: #E8DEFB;
}