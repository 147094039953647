.skeleton {
    position: relative;
    background-color: #7f56d963;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.skeleton::after {
    display: block;
    content: "";
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background: -webkit-gradient(linear, left top,
            right top, from(transparent),
            color-stop(rgba(255, 255, 255, 0.2)),
            to(transparent));

    background: linear-gradient(90deg, transparent,
            rgba(255, 255, 255, 0.2), transparent);

    animation: loading 0.8s infinite;
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}