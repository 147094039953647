.form-container {
  margin: 0 auto;
  width: 90%;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem; /* Adjust margin between rows as needed */
}

.form-group {
  flex: 1 0 50%; /* Each form group takes up 50% of the row width */
  padding-right: 1.5rem; /* Adjust spacing between form elements as needed */
}

.form-label {
  /* Typography for label */
  color: var(--colors-text-text-secondary-700, #344054);
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.form-label.text-md-end {
  text-align: right; /* Align labels to the right when required */
}

.form-control-input,
.form-control-textarea {
  /* Typography for placeholder */
  color: var(--Colors-Text-text-placeholder, #101828);
  padding: 6px;
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Colors-Border-border-primary, #d0d5dd);
  background: var(--Colors-Background-bg-primary, #fff);

  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  /* Layout for placeholder */
  flex: 1 0 0;
  align-self: stretch;
  width: 100%; /* Ensure input takes full width */
}

.form-control-input:focus,
.form-control-textarea:focus{
  box-shadow: 0px 0px 0px 4px rgba(158, 119, 237, 0.24) !important;  
  border: 1px solid var(--Colors-Border-border-brand, #D6BBFB) !important;
  outline: none !important;
}


.form-control-textarea {
  height: 120px; /* Set height for textarea */
}

.button-primary-sw {
  width: 156.28px;
  height: 41.92px;

  padding-top: 10.46px;
  padding-bottom: 10.46px;
  background: #7f56d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 1.05px #7f56d9 solid;

  gap: 4px;

  padding-left: 2px;
  padding-right: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-size: 14px;
  /* font-family: "Inter", Helvetica; */
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
}

.upload-disable-control {
  display: flex;
  width: 100%;
  padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs, 4px);

  border-radius: var(--radius-xl, 12px);
  background: rgb(235, 233, 233);
  cursor: not-allowed;
}

.upload-control1 {
  display: flex;
  width: 100%;
  padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs, 4px);

  border-radius: var(--radius-xl, 12px);
  background: var(--Colors-Background-bg-primary, #fff);
  cursor: pointer;
}

.upload-control1 input[type="file"] {
  display: none;
}

.upload-control {
  display: flex;
  width: 100%;
  padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs, 4px);

  border-radius: var(--radius-xl, 12px);
  border: 2px solid var(--Colors-Border-border-brand-solid, #7f56d9);
  background: var(--Colors-Background-bg-primary, #fff);
  cursor: pointer;
}

.upload-control input[type="file"] {
  display: none;
}

.uploadicon {
  border-radius: var(--radius-md, 8px);
  border: 1px solid
    var(
      --Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border,
      #eaecf0
    );
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.upload-options {
  display: flex; /* Arrange "Click" and "or drag and drop" horizontally */
  justify-content: center; /* Align them left and right */
  width: 100%; /* Ensure full width within the container */
  /* Add other styles for text size, color, etc. */
}

.click,
.drag {
  cursor: pointer; /* Indicate interactivity for clickable text */
  color: var(
    --Component-colors-Components-Buttons-Tertiary-color-button-tertiary-color-fg,
    #6941c6
  );
  /* Text sm/Semibold */
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.upload-types {
  margin-top: 10px; /* Add vertical spacing below the options */
  text-align: center; /* Center text for supported file types */
  font-size: 0.8rem; /* Adjust font size as needed */
  color: #808080; /* Use a muted color for file type information */
  color: var(--colors-text-text-tertiary-600, #475467);
  text-align: center;
  /* Text xs/Regular */
  /* font-family: "Inter", Helvetica; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.selected-files {
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  background: var(--Colors-Background-bg-primary, #fff);
}
.selected-file-grouping {
  display: flex;
  align-items: center; /* Align icon and details vertically */
  padding: 8px 16px; /* Add padding for spacing */
  cursor: default; /* Prevent default pointer behavior (optional) */
}

.icons-files {
  margin-right: 16px; /* Add space between icon and details */
  display: flex; /* Allow for stacking icons if needed */
  align-items: center;
}

.file-details {
  flex: 1; /* Allow details to fill remaining space */
}

.filename {
  white-space: nowrap; /* Prevent wrapping onto multiple lines */
  overflow: hidden;
  color: var(--colors-text-text-secondary-700, #344054);
  text-overflow: ellipsis;

  /* Text sm/Medium */
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.filetype {
  overflow: hidden;
  color: var(--colors-text-text-tertiary-600, #475467);
  text-overflow: ellipsis;

  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.btn-center {
  margin: 0 auto; /* Center the button horizontally */
}

.image-container {
  width: 100%;
  position: relative;
}

.image-container img {
  min-width: 100%;
  max-width: 100%;
  width: auto;
  height: 100px;
}



@media (max-width: 768px) {
  .form-group {
    flex: 1 0 100%; /* Each form group takes up 100% of the row width on smaller screens */
  }

  .form-group:nth-child(2) {
    order: 3; /* Change the order of the second form group to be after the third one */
  }

  .form-group:nth-child(3) {
    order: 2; /* Change the order of the third form group to be before the second one */
  }
}
