.input-container {
    display: flex;
    flex-direction: column;
}

.input-label {
    margin-bottom: 0.5rem;
    font-weight: normal;
}

.input-important {
    color: red;
    margin-left: 0.25rem;
}

.input-text {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}



.textarea-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.textarea-label {
    margin-bottom: 0.5rem;
    font-weight: normal;
}

.textarea-important {
    color: red;
    margin-left: 0.25rem;
}

.textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical; /* Allows vertical resizing */
}

.character-counter {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #333;
}

.character-counter.limit-exceeded {
    color: red;
}
