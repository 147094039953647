.custom-date-range-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .date-range-button {

    width: 100%;
    height: 40px;
    padding: 10px 14px ;
    gap: 10px;
    border-radius: 8px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border: 1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD);
    /* box-shadow: 0px 0px 0px 4px #98A2B324; */
    box-shadow: 0px 1px 2px 0px #1018280D;
    text-align: start;

  }
  /* .date-range-button:hover,.date-range-button:focus{
    box-shadow: 0px 0px 0px 4px #98A2B324;
  } */


  .calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50px;
    right:0;
    z-index: 111;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .calendar {
    display: flex;
    flex-direction: column;
    /* margin: 10px ; */
    padding: 20px;
    border-bottom: 1px solid #ccc;
    
  }
  .left-b{
    border-left: 1px solid #ccc;

  }
  
  .calendar-header {
    text-align: center;
    font-weight: bold;
    padding: 10px 10px;
    /* border-bottom: 1px solid #ccc; */
    display: flex;
    color: #344054;
    justify-content: space-between;
  }
  
  .calendar-body {
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: center;
    /* padding: 10px; */
    

  }
  
  .calendar-week {
    display: flex;
    width: 100%;
  }
  
  .calendar-day,.weekdayName{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .calendar-day:hover {
    background-color: #f0f0f0;
  }
  
  .calendar-day.in-range {
    background-color: #e0e0ff;
  }
  
  .calendar-day.start,
  .calendar-day.end {
    background-color: #6200ea;
    color: white;
  }

 
  .empty {
    background-color: transparent;
  }
  
  .actions {
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 10px;
    gap:15px
  }
  
  .clear-button,
  .apply-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
  }
  
  .clear-button {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .apply-button {
    background-color: #6200ea;
    color: white;
  }
  
  .calendar-day.disabled {
    background-color: #f0f0f0;
    color: #d3d3d3;
    pointer-events: none;
  }
  
  .calendar-day.disabled:hover {
    cursor: not-allowed;
  }