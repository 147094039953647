/* Tooltip.css */

/* General Tooltip Container Styles */
.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  /* Tooltip Text Styles */
  .tooltip-text {
    visibility: hidden;
    width: max-content;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip Arrow Styles */
  .tooltip-arrow {
    position: absolute;
    border-width: 5px;
    border-style: solid;
  }
  
  /* Positioning the Tooltip Text and Arrow */
  .tooltip-top .tooltip-text {
    bottom: 125%; /* Position the tooltip above the container */
    left: 50%;
    transform: translateX(-50%);
  }
  
  .tooltip-top .tooltip-arrow {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip-bottom .tooltip-text {
    top: 125%; /* Position the tooltip below the container */
    left: 50%;
    transform: translateX(-50%);
  }
  
  .tooltip-bottom .tooltip-arrow {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-color: transparent transparent #555 transparent;
  }
  
  .tooltip-left .tooltip-text {
    top: 50%;
    right: 125%;
    transform: translateY(-50%);
  }
  
  .tooltip-left .tooltip-arrow {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-color: transparent #555 transparent transparent;
  }
  
  .tooltip-right .tooltip-text {
    top: 50%;
    left: 125%;
    transform: translateY(-50%);
  }
  
  .tooltip-right .tooltip-arrow {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-color: transparent transparent transparent #555;
    transform: rotate(180deg);

  }
  
  /* Visibility on Hover */
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  