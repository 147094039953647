.grid-container {
  padding: 20px 40px;
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
}

.latest-commission {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: var(--radius-md, 8px);
  background: #f9fafb;
  min-height: 450px;
  flex-shrink: 0;
  max-height: 590px;
}

.latest-commission h2 {
  margin-top: 0;
}

.commission-details p {
  margin: 5px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: var(--radius-md, 8px); /* Add border radius */
  overflow: hidden; /* Ensure rounded corners are visible */
  border: 1px solid #eaecf0; /* Outer border color */
}

th,
td {
  border-top: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  border-bottom: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f9fafb;
}

th:first-child,
td:first-child {
  border-left: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
}

th:last-child,
td:last-child {
  border-right: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
}

tbody .even-row {
  background-color: #ffffff;
}

tbody .odd-row {
  background-color: #f9fafb;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  cursor: pointer;
  border-radius: var(--radius-md, 8px); /* Add border radius */
}

.pagination li.active {
  background-color: #f9fafb;
  color: black;
}

.pagination-container button {
  padding: 5px 10px;
  cursor: pointer;
}

.pagination-container button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.statusContainer {
  border: 1px solid var(--success-green);
  display: flex;
  gap: 10px;
  padding: 5px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
}

.completedStatus {
  border: 1px solid var(--success-green) !important;
  border-radius: 25px;
  color: var(--success-green) !important;
  background-color: white !important;
}

.status {
  height: 25px;
  width: auto;
  display: inline-flex;
  padding: var(--spacing-xxs, 2px) 10px;
  align-items: center;
  /* border-radius: var(--radius-full, 5px); */
  border-radius: 25px;
  border: 1px solid
    var(--Component-colors-Utility-Blue-utility-blue-200, #b2ddff);
  background: var(--Component-colors-Utility-Blue-utility-blue-50, #eff8ff);
  color: var(--Component-colors-Utility-Blue-utility-blue-700, #175cd3);
  text-align: center;
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
}

.latest-commission-name-footer {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--colors-text-text-tertiary-600, #475467);
  text-overflow: ellipsis;
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.latest-commission-name {
  align-self: stretch;
  color: var(--colors-text-text-primary-900, #101828);
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.details-row {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.details-row .details-right {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.fields {
  width: 50%;
  display: flex;
  align-items: center;
}

.tabs {
  margin-top: 10px;
  display: flex;
  /* width: 975px; */
  padding: var(--spacing-xs, 4px);
  align-items: center;
  gap: var(--spacing-xs, 4px);
  border-radius: var(--radius-lg, 10px);
  border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  background: var(--Colors-Background-bg-secondary_alt, #f9fafb);
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
}

.tabs button {
  display: flex;
  height: 20px;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  justify-content: center;
  align-items: center;
  all: unset;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 75px;
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  font-size: 14px;
  color: #667085;
}

.tabs button.active {
  border-radius: var(--radius-lg, 10px);
  /* border: 1px solid var(--Colors-Border-border-secondary, #eaecf0); */
  background: var(--Colors-Background-bg-secondary_alt, #ffffff);
  display: flex;
  height: 20px;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  justify-content: center;
  align-items: center;
  min-width: 75px;
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  color: #344054;
  /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06); */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);

}

.tab-content {
  padding: 10px 30px;
  /* margin: 0 auto; */
  max-width: 70%;
  overflow-y: auto;
  max-height: 330px;
  margin-top: 5px;
}

.tab-content::-webkit-scrollbar {
  width: 8px;
}

.tab-content::-webkit-scrollbar-track {
  background: white; /* color of the track */
}

.tab-content::-webkit-scrollbar-thumb {
  background: #ECECEC; /* color of the scrollbar thumb */
  border-radius: 10px; /* roundness of the scrollbar thumb */
}

.button-placement1 {
  display: flex;
  gap: 25px;
  justify-content: right;
}

.heading-text {
  color: #000;
  /* font-family: "Inter", Helvetica; */
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
}

.font-style-grid {
  color: #475467;
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.previous-button {
  display: flex;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs, 4px);
  border-radius: var(--radius-md, 8px);
  border: 1px solid
    var(
      --Component-colors-Components-Buttons-Secondary-button-secondary-border,
      #d0d5dd
    );
  background: var(
    --Component-colors-Components-Buttons-Secondary-button-secondary-bg,
    #fff
  );

  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.next-button {
  display: flex;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs, 4px);
  border-radius: var(--radius-md, 8px);
  border: 1px solid
    var(
      --Component-colors-Components-Buttons-Secondary-button-secondary-border,
      #d0d5dd
    );
  background: var(
    --Component-colors-Components-Buttons-Secondary-button-secondary-bg,
    #fff
  );

  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.thump-image {
  max-width: none;
  width: 108px;
  height: 53px;
}

.noActiveCommissionText {
  font-size: 20px;
  font-weight: 400;
  color: #475467;
  line-height: 30px;
  margin-top: 50px;
  margin-bottom:20px;
}

@media (max-width: 600px) {
  .commission-details {
    display: flex;
    flex-direction: column;
  }

  .commission-details p {
    font-size: 14px;
  }

  table,
  th,
  td {
    font-size: 12px;
  }

  .pagination li {
    padding: 3px 7px;
  }
}
