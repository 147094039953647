.css-t3ipsp-control:hover{
    border-color: white !important;
}

.css-1u9des2-indicatorSeparator{
    width: 0px !important;
}

.css-qr46ko{
    max-height: 120px !important;
    overflow-y: auto;
    margin: 2px 2px 2px 0;
   
}



.css-1uotdqy-option,.css-wngml1-option:hover{
    background-color:   #F9FAFB !important;
}

.css-hlgwow   .optionSelected{
    display: none;
} 








    /* Entire scrollbar */
    .css-qr46ko::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
        height: 3px; /* Height of the horizontal scrollbar */
        
    }
    
    /* Track */
    .css-qr46ko::-webkit-scrollbar-track {
        background: #fff; /* Background color of the track */
        border-radius: 10px; /* Round corners of the track */
    }
    
    /* Handle */
    .css-qr46ko::-webkit-scrollbar-thumb {
        background: #EAECF0; /* Color of the scrollbar handle */
        border-radius: 10px; /* Round corners of the handle */

    }
    
    /* Handle on hover */
    .css-qr46ko::-webkit-scrollbar-thumb:hover {
        background: #EAECF0; /* Color of the scrollbar handle on hover */
    }
    
    /* Corner piece where horizontal and vertical scrollbars meet */
    .css-qr46ko::-webkit-scrollbar-corner {
        background: #f1f1f1; /* Background color of the corner */
        ;
    }
    