.intro-commission {
  display: flex;
  align-items: center;

  align-items: center;
  gap: 20px;
  padding: 10px 40px;
  flex-wrap: wrap;
}

.new-symbol {
  display: inline-flex;
  padding: 2px 10px; /* Using fixed padding values */
  align-items: center;

  border-radius: 9999px; /* Using a fixed border radius */
  border: 1px solid #abefc6; /* Using a fixed border color */
  background: #ecfdf3; /* Using a fixed background color */

  color: #067647; /* Using a fixed text color */
  text-align: center;

  /* Text size and style */
  /* font-family: "Inter", Helvetica; */
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* Using a fixed line-height value */
  margin-right: 10px;
}

.new-commission-text {
  color: #000; /* Using a fixed text color */
  /* font-family: "Inter", Helvetica; */
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2; /* Using a fixed line-height value */
}

.commission-collage {
  display: flex; /* Allow stacking columns side-by-side */
  width: 100%; /* Adjust width as needed */
  /* min-width: 600px; */
  /* max-width: 650pxpx; */
  justify-content: center;
}

.image-container-gallery {
  display: flex; /* Arrange columns horizontally */
  width: 70%;
  margin-top: 20px;
  max-height: 250px;
}

.image-column {
  padding: 2px; /* Add spacing around images */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.image-column.first {
  width: 50%; /* First column takes two-thirds of the space */
  height: 100%; /* Full height of the container */
}

.image-column.second {
  width: 50%; /* Second column takes one-third of the space */
  display: flex; /* Arrange images within the second column */
  flex-direction: column; /* Stack images vertically */
  gap: 5px;
}

.image-column.second img {
  flex: 1; /* Both images in second column share available height equally */
}

.image-column img {
  width: 100%; /* Images fill the container width */
  object-fit: cover; /* Crop images to fit */
}

.FeaturedIcon {
  width: 50.2px;
  height: 50.2px;
  position: relative;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10.45px;
  border: 1.05px #eaecf0 solid;
}

.Edit04 {
  width: 25.1px;
  height: 25.1px;
  left: 12.55px;
  top: 12.55px;
  position: absolute;
}

.Icon {
  width: 18.83px;
  height: 17.91px;
  left: 3.14px;
  top: 3.01px;
  position: absolute;
  border: 2.09px #344054 solid;
}

.TextAndSupportingText {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2.09px;
  display: inline-flex;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-middle {
  display: block;
  margin: 0 auto; /* This centers the image horizontally */
  text-align: center;
}
